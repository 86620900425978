<template>
  <div class="tw-relative tw-w-full tw-mt-10 lg:mt-4">
    <div class="lg:tw-max-w-7xl tw-mx-6 lg:tw-mx-auto">

    Ich bin die Addresse

    </div>
  </div>
</template>

<script>

export default {
  name: 'Address',
}
</script>
